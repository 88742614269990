import React from 'react';

const Caret = () => {
  return (
    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1.07412L0.838313 0.277344L6 5.18303L0.838313 10.0888L0 9.29198L3.73048 5.74649L4.32333 5.18303L3.73048 4.61966L0 1.07412Z" fill="white"/>
    </svg>
  );
};

export default Caret;