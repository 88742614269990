// extracted by mini-css-extract-plugin
export var breadCrumb = "blog-module--breadCrumb--dGd3F";
export var blogFeatured = "blog-module--blogFeatured--0Exn9";
export var blogLink = "blog-module--blogLink--M0tuW";
export var blogFeaturedCol6 = "blog-module--blogFeaturedCol6--PcnjN";
export var postImageContainer = "blog-module--postImageContainer--UP3xq";
export var postImage = "blog-module--postImage--sbuya";
export var blogFeaturedCol3 = "blog-module--blogFeaturedCol3--40boX";
export var blogFeaturedTextWrapper = "blog-module--blogFeaturedTextWrapper--7iMBZ";
export var blogFeaturedTitle = "blog-module--blogFeaturedTitle--xBts6";
export var blogListDesc = "blog-module--blogListDesc--gZ5S4";
export var blogListDate = "blog-module--blogListDate--or1DD";
export var root = "blog-module--root--gVuY+";
export var customTab = "blog-module--customTab--FUYih";
export var active = "blog-module--active--rsqoN";
export var content = "blog-module--content--XVLCL";
export var mobileContainer = "blog-module--mobileContainer--oYDhA";
export var section = "blog-module--section--VUlWY";
export var hide = "blog-module--hide--k14Rr";