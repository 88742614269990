import React, { useContext, useState, useEffect, useRef } from 'react';
import BlogListsContext from '../context/BlogListsProvider';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import Container from '../components/atoms/Container/Container';
import Icon from '../components/atoms/Icon/Icon';
import PostListContentful from '../components/organisms/PostListContentful/PostListContentful';
import * as styles from './blog.module.css';
import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs';

const BlogContentful = () => {
  const blogListsContext = useContext(BlogListsContext);
  const [title, setTitle] = useState(0);
  const [blog, setBlog] = useState([]);
  const [ftBlogDesc, setFtBlogDesc] = useState([]);
  const [ftBlogDescExcept, setFtBlogDescExcept] = useState('');
  const [ftBlogTitleExcept, setFtBlogTitleExcept] = useState('');
  const sliderAnchor = useRef();
  const containerAnchor = useRef();

  const handleClickOnTab = e => {
    setTitle(e);
  };

  const featuredLink = blogListsContext?.events[title]?.blogItems[0]?.slug
    ? `/blog/${blogListsContext.events[title]?.blogItems[0]?.slug.replace(
        /^\/|\/$/g,
        ''
      )}`
    : '';

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const activeTab = window.location.hash;
      if (activeTab) {
        let clean = activeTab.replace('#', '').toLowerCase();
        if (['news', 'events'].includes(clean)) {
          handleClickOnTab(clean);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (
      blogListsContext &&
      blogListsContext.events &&
      blogListsContext.events[title]?.blogItems.length > 0
    ) {
      const event = blogListsContext.events[title];
      setBlog(
        event.blogItems.sort(
          (a, b) => Date.parse(b.publishDate) - Date.parse(a.publishDate)
        )
      );
      const parsedDesc = JSON.parse(event.blogItems[0].blogContent.raw)
        .content[0].content[0];
      console.log(blog, 'Blog');
      setFtBlogDesc(parsedDesc ? parsedDesc.value : null);
    }
  }, [title, blogListsContext]);

  useEffect(() => {
    const str = ftBlogDesc ? ftBlogDesc : 'Description';
    setFtBlogDescExcept(
      str.length > 150 ? str.substring(0, 150).concat('...') : str
    );
  }, [ftBlogDesc]);

  useEffect(() => {
    const event = blogListsContext?.events[title]?.blogItems[0];
    const blogTitle = event?.blogTitle || 'Description';
    setFtBlogTitleExcept(
      blogTitle.length > 50
        ? blogTitle.substring(0, 50).concat('...')
        : blogTitle
    );
  }, [blogListsContext, title]);

  return (
    <Layout displaySubscribe='true'>
      <Seo title={'News & Views'} />
      <Container size='medium'>
        <Breadcrumbs
          crumbs={[{ link: '/', label: 'Home' }, { label: 'News & Views' }]}
        />
        {blogListsContext &&
          blogListsContext.events[title]?.blogItems.length > 0 && (
            <div className={styles.blogFeatured}>
              <div className={styles.blogFeaturedCol6}>
                <a href={featuredLink}>
                  <div className={styles.postImageContainer}>
                    <img
                      className={styles.postImage}
                      src={
                        blogListsContext.events[title]?.blogItems[0]
                          ?.blogFeaturedImage?.file?.url || ''
                      }
                      alt='title banner helmet'
                    />
                  </div>
                </a>
              </div>
              <div className={styles.blogFeaturedCol3}>
                <div className={styles.blogFeaturedTextWrapper}>
                  <div className={styles.blogFeaturedTitle}>
                    {ftBlogTitleExcept}
                  </div>
                  <div className={styles.blogListDesc}>{ftBlogDescExcept}</div>
                  <a className={styles.blogLink} href={featuredLink}>
                    Read Article <Icon symbol='arrowRightLong' fill='#7B2CBF' />
                  </a>
                </div>
              </div>
            </div>
          )}
        <div className={styles.root} ref={containerAnchor}>
          <div ref={sliderAnchor}>
            <ul className={styles.customTab} data-active={title}>
              {blogListsContext &&
                blogListsContext.events.map((item, index) =>
                  title === index ? (
                    <li
                      className={styles.active}
                      key={index}
                      role='presentation'
                      onClick={() => handleClickOnTab(index)}>
                      {item.name}
                    </li>
                  ) : (
                    <li
                      key={index}
                      role='presentation'
                      onClick={() => handleClickOnTab(index)}>
                      {item.name}
                    </li>
                  )
                )}
            </ul>
            <div>
              <PostListContentful
                posts={blog}
                viewMode={'grid'}
                cardSize={'large'}
                titleOnly={false}
              />
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default BlogContentful;

