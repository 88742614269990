import React from 'react';
import Caret from '../../../assets/svg/caret';
import * as styles from './Breadcrumbs.module.css';

const Breadcrumbs = ({ crumbs, isSpacerWhite }) => {
    let crumbsOutput = crumbs;
    if (crumbsOutput && typeof crumbsOutput !== 'object') {
        if (crumbsOutput.indexOf('>') > -1) {
            crumbsOutput = crumbsOutput.split('>');
        } else {
            crumbsOutput = [crumbsOutput];
        }
    }
    return (
        <div className={styles.breadCrumbWrapper}>
            {crumbsOutput && crumbsOutput.map((crumb, crumbIndex) => (
                <span key={crumbIndex}>
                    {crumbIndex > 0 && (
                        <span className={!!isSpacerWhite ? styles.spacerWhite : styles.spacer}>
                            <Caret />
                        </span>
                    )}
                    {typeof crumb === 'object' && 'link' in crumb && crumb.link !== '' && (
                        <a className={styles.crumb} href={crumb.link}>{crumb.label.trim()}</a>
                    )}
                    {typeof crumb === 'object' && (!('link' in crumb) || crumb.link === '') && (
                        <span className={styles.crumb}>{crumb.label?.trim()}</span>
                    )}
                    {typeof crumb !== 'object' && (
                        <span className={styles.crumb}>{crumb.trim()}</span>
                    )}
                </span>
            ))}
        </div>
    )
}

export default Breadcrumbs